import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Helmet from "react-helmet";

export const ThankYouPageTemplate = ({ title, content, contentComponent, image }) => {
    const PageContent = contentComponent || Content

    return (
        <section className="section section--gradient">
            <div className="container content">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                            {title}
                        </h2>
                        <PageContent className="content" content={content} />
                    </div>
                </div>
            </div>
        </section>
    )
}

ThankYouPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const ThankYouPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout>
            <ThankYouPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
                helmet={
                    <Helmet titleTemplate="%s | Painting">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
            />
        </Layout>
    )
}

ThankYouPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ThankYouPage

export const ThankYouPageQuery = graphql`
    query ThankYouPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`
